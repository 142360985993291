//
// Component: Pagination
//

.pagination-month {
  .page-item {
    justify-self: stretch;

    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &:first-child,
    &:last-child {
      .page-link {
        height: 100%;
        font-size: $font-size-lg;
      }
    }
    .page-month {
      margin-bottom: 0;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
    .page-year {
      margin-bottom: 0;
    }
  }

  &.pagination-lg {
    .page-month {
      font-size: ($font-size-lg * 1.25);
    }
  }
  &.pagination-sm {
    .page-month {
      font-size: ($font-size-base);
    }
  }
}
