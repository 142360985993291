//
// Component: Callout
//

// Base styles (regardless of theme)
.callout {
  @if $enable-rounded {
    @include border-radius($border-radius);
  }

  @if $enable-shadows {
    box-shadow: map-get($elevations, 1);
  } @else {
    border: 1px solid $gray-300;
  }

  background-color: $white;
  border-left: 5px solid $gray-200;
  margin-bottom: map-get($spacers, 3);
  padding: 1rem;

  a {
    color: $gray-700;
    text-decoration: underline;

    &:hover {
      color: $gray-200;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  // Themes for different contexts
  &.callout-danger {
    border-left-color: darken(theme-color("danger"), 10%);
  }

  &.callout-warning {
    border-left-color: darken(theme-color("warning"), 10%);
  }

  &.callout-info {
    border-left-color: darken(theme-color("info"), 10%);
  }

  &.callout-success {
    border-left-color: darken(theme-color("success"), 10%);
  }
}
